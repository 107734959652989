import { Sentry } from "./sentryInstrument";


async function setSECodeCookie(endpoint, retriesLeft) {
  const url = new URL(endpoint);
  url.searchParams.append("seref", document.referrer);
  try {
	const response = await fetch(url.toString());
	if (!response.ok) {
	  throw new Error(`Unexpected ${response.statusText} response ${response.status} from ${url}`);
	}
  } catch (error) {
	if (retriesLeft > 0) {
	  await new Promise(r => setTimeout(r, 2000));	// NOTE: sleep 2 seconds
	  await setSECodeCookie(url, retriesLeft - 1);
	} else {
	  if (Sentry && Sentry.getClient()) {
		Sentry.captureException?.(error);
	  }
	}
  }
}

window.addEventListener("DOMContentLoaded", () => {
  if (window.setSECodeURL) {
	setSECodeCookie(window.setSECodeURL, 4);
  }
});
